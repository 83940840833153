<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- defaultStart="-6M"
            defaultEnd="0M" -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="최근검진 기간"
            name="checkupRecentPeriod"
            v-model="searchParam.checkupRecentPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="검진예정 기간"
            defaultStart="0M"
            defaultEnd="6M"
            name="checkupSchedulePeriod"
            v-model="searchParam.checkupSchedulePeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept :isFirstValue="false" type="search" label="LBLDEPT" name="deptCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-field label="대상자" name="userId" v-model="searchParam.userId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            :comboItems="cycleItems"
            name="typeName"
            label="검진종류"
            v-model="searchParam.typeName"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="userTable"
      title="특수검진 주기 및 도래 현황 목록"
      tableId="userTable"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='checkupComeDate'">
          <span>
            {{props.row.nextDate}}
            <q-badge v-if="props.row.checkupComeDate < 0 && props.row.checkupComeDate >= -7" color="negative" :label="Math.abs(props.row.checkupComeDate)+'일전'" class="blinking" />
            <q-badge v-else-if="props.row.checkupComeDate > 0" color="primary" label="검진예정일이 지남" class="blinking" />
            <q-badge v-else-if="props.row.checkupComeDate == -9999" color="orange" label="검진 데이터 없음"/>
          </span>
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="엑셀 업로드" icon="upload"  @btnClicked="openExcelUploader"/>
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveCycle"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-status',
  data() {
    return {
      cycleItems: [
        { code: 'spe', codeName: '특수' },
        { code: 'batch', codeName: '배치전' },
        { code: 'occ', codeName: '수시' },
      ],
      searchParam: {
        plantCd: null,
        deptCd: '',
        userId: '',
        checkupRecentPeriod: [],
        checkupSchedulePeriod: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'userId' },
          { index: 1, colName: 'userId' },
          { index: 2, colName: 'userId' },
          { index: 3, colName: 'userId' },
          { index: 4, colName: 'userId' },
          { index: 10, colName: 'userId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            style: 'width: 80px',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width: 100px',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            style: 'width: 80px',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'userName',
            field: 'userName',
            style: 'width: 100px',
            label: '대상자',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            style: 'width: 100px',
            label: '생년월일',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'cycleName',
            field: 'cycleName',
            style: 'width: 100px',
            label: '검진종류',
            align: 'center',
            sortable: true,
            fix: true,
          },
          // {
          //   name: 'spotName',
          //   field: 'spotName',
          //   style: 'width: 80px',
          //   label: '직위',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'jobName',
          //   field: 'jobName',
          //   style: 'width: 80px',
          //   label: '직책',
          //   align: 'center',
          //   sortable: true,
          // },
          // {
          //   name: 'birthDate',
          //   field: 'birthDate',
          //   style: 'width: 100px',
          //   label: '생년월일',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            // required: true,
            name: 'cycleCd',
            field: 'cycleCd',
            label: '검진주기',
            align: 'center',
            style: 'width:120px',
            type: 'select',
            setHeader: true,
            codeGroupCd: 'CHECK_UP_CYCLE_CD',
            sortable: false,
          },
          {
            name: 'checkupRecentDate',
            field: 'checkupRecentDate',
            label: '최근 검진일',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupScheduleDate',
            field: 'checkupScheduleDate',
            label: '검진 예정일',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkupComeDate',
            field: 'checkupComeDate',
            label: '검진일 도래',
            align: 'center',
            type: 'custom',
            style: 'width: 120px',
            sortable: true,
          },
          {
            name: 'checkupStatus',
            field: 'checkupStatus',
            label: '비고',
            align: 'center',
            type: 'textarea',
            style: 'width: 200px',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveable: false,
      editable: true,
      isSave: false,
      saveUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      cycleCdItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$comm.getComboItems('CHECK_UP_CYCLE_CD').then(_result => {
        this.cycleCdItems = _result
      });
      // api scope
      // api scope
      this.listUrl = selectConfig.mdm.user.cycle.list.url;
      this.saveUrl = transactionConfig.mdm.user.cycle.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveCycle() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    openExcelUploader() {
      this.popupOptions.title = '대상자별 특수검진 주기 업로드';
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./checkUpStatusExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        
        this.$_.forEach(s_data, item => {
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          item.editFlag = 'C';
          let idx = this.$_.findIndex(this.cycleCdItems, {codeName: item.cycleName})
          if (idx > -1) {
            item.cycleCd = this.cycleCdItems[idx].code
          } else {
            item.cycleCd = null;
          }
        })
        this.$http.url = transactionConfig.mdm.user.cycle.uploadSave.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    }
  }
};
</script>
